



const loadBlocks = (editor, config) => {
   
    editor.BlockManager.add('my-text-block', {
      label: 'My Text Block',
      content: '<div class="my-text-block">This is my custom text block</div>',
    });

      editor.BlockManager.add('my-image-block', {
        label: 'My Image Block',
        content: '<div class="my-image-block"><img src="path/to/image.jpg" alt="My Image" /></div>',
      });

      editor.BlockManager.add('my-hero-block', {
        label: 'My Hero Section',
        content: `
            <section class="my-hero-block">
                <div class="hero-content">
                    <h1>Welcome to My Website</h1>
                    <img src ="" alt="Img"  />
                    <p>This is the hero section of my website.</p>
                </div>
            </section>
        `,
    });

    editor.BlockManager.add('my-text-content-block', {
        label: 'My Text Content Block',
        content: '<div class="my-text-content-block">This is my custom text content block</div>',
    });
   
    editor.BlockManager.add('CTA', {
        label: 'Call to Action (CTA) Block',
        content: `
            <div class="cta-block">
                <label for="cta-title" class="form-label">Title:</label>
                <input type="text" class="form-control" id="cta-title" name="cta-title" placeholder="">
    
                <label for="cta-isNewTab" class="form-check-label"> New Tab:</label>
                <input type="checkbox" class="form-check-input" id="cta-isNewTab" name="cta-isNewTab" checked>
    
                <a id="cta-button" href="#" target="_blank">
                    <button class="btn btn-primary">CTA Button</button>
                </a>
            </div>
        `,
    });

    editor.BlockManager.add('my-quotes-block', {
        label: 'My Quotes Block',
        content: `
            <div class="my-quotes-block">
                <blockquote>
                    <p>"This is a quote. Replace it with your own inspiring words".</p>
                    <footer>- Author</footer>
                </blockquote>
            </div>
        `,
    });
   
   
    editor.BlockManager.add('my-image-gallery-block', {
        label: 'Image Gallery Block',
        content: `
            <div class="my-image-gallery-block">
                <div class="row">
                    <div class="col-md-4">
                        <div class="gallery-item">
                            <div class="image-container">
                                <img src="path/image1.jpg" alt="Image 1" />                                <h3 class="image-heading">Image 1 Heading</h3>
                            </div>
                            <div class="image-info">
                                <p class="caption">Caption for Image 1</p>
                                <p class="description">Description for Image 1 goes here.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gallery-item">
                            <div class="image-container">
                                <img src="path/image2.jpg" alt="Image 2">
                                <h3 class="image-heading">Image 2 Heading</h3>
                            </div>
                            <div class="image-info">
                                <p class="caption">Caption for Image 2</p>
                                <p class="description">Description for Image 2 goes here.</p>
                            </div>
                        </div>
                    </div>
           
             </div>
             <div class="col-md-4">
             <div class="gallery-item">
             <div class="image-container">
                 <img src="path/image3.jpg" alt="Image 2">
                 <h3 class="image-heading">Image 3 Heading</h3>
             </div>
             <div class="image-info">
                 <p class="caption">Caption for Image 3</p>
                 <p class="description">Description for Image 3 goes here.</p>
             </div>
         </div>
             </div>

            </div>
                <div class="gallery-container">
                    
                   
                    <!-- Add more gallery items as needed -->
                </div>
            </div>
        `,
    });


   
  };
  
  export default loadBlocks;
  