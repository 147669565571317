// App.js
import React from 'react';
import Grape from './components/grape';


function App() {
  return (
    <div className="App">
      <Grape />
    </div>
  );
}

export default App;
