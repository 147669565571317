import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs-preset-webpage';
import 'grapesjs-tui-image-editor';
import 'grapesjs-navbar';
import 'grapesjs-blocks-basic';
import GrapeJSTuiImageEditorPlugin from './GrapeJSTuiImageEditorPlugin';
import loadBlocks from './loadBlock';

const GrapeJSComponent = () => {
  // Use state to store the editor instance
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    // Initialize GrapeJS
    const grapeEditor = grapesjs.init({
      container: '#gjs-container',
      plugins: [
        'grapesjs-blocks-basic',
        'grapesjs-tui-image-editor',
        'grapesjs-preset-webpage',
        'grapesjs-lory-slider',
        'grapesjs-navbar',
      ],
      // Add more GrapeJS options as needed
    });

    // Store the editor instance in state
    setEditor(grapeEditor);

    // Load additional blocks
    loadBlocks(grapeEditor, {});

    console.log(grapeEditor);
  }, []); 

  return (
    <div>
      
      {editor && <GrapeJSTuiImageEditorPlugin editor={editor} options={{/* Specify your plugin options */}} />}

      {/* Render the GrapeJS editor container */}
      <div id="gjs-container"></div>
    </div>
  );
};

export default GrapeJSComponent;
